import React, { useEffect, useState } from "react";
import {
  AdvancedMarker,
  APIProvider,
  InfoWindow,
  Map,
  useAdvancedMarkerRef,
  useMap,
  Pin,
} from "@vis.gl/react-google-maps";
import { Typography, useTheme } from "@mui/material";
import {
  glyphHtmlHelper,
  infoWindowHtmlHelper,
  validatePlaces,
} from "../utils/maps_utils";
import { EmptyState, Loader } from "./";
import { GoogleMapsLibraries, MapHasNoPlacesExplanation } from "../constants";

export const MapView = ({
  places,
  styleOverrides,
  callee,
  showFavorites,
  onClickOverride = undefined,
}) => {
  const allOrFavorites = showFavorites
    ? places?.favorites
    : places?.all || places; // profile and explore send array

  const validPlaces = validatePlaces(allOrFavorites);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (validPlaces && validPlaces.length > 0) setLoading(false);
  }, [validPlaces]);

  return (
    validPlaces && (
      <APIProvider
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        version="beta"
        libraries={GoogleMapsLibraries}
      >
        <MapElement
          places={places}
          validPlaces={validPlaces}
          styleOverrides={styleOverrides}
          callee={callee}
          loading={loading}
          setLoading={setLoading}
          showFavorites={showFavorites}
          onClickOverride={onClickOverride}
        />
      </APIProvider>
    )
  );
};

const MapElement = ({
  places,
  validPlaces,
  styleOverrides,
  callee,
  loading,
  setLoading,
  showFavorites,
  onClickOverride,
}) => {
  const [activeMarker, setActiveMarker] = useState(null);
  const map = useMap();
  const ZOOM_LEVEL = 3;
  const ZOOM_LEVEL_FOR_SINGLE_MARKER = 10;

  // https://github.com/visgl/react-google-maps/discussions/93#discussioncomment-9698167
  useEffect(() => {
    if (!map) return;
    if (validPlaces?.length > 0 && map) {
      fitMapToBounds(validPlaces);
    }
  }, [map, validPlaces]);

  const fitMapToBounds = (validPlaces) => {
    const markers = validPlaces.map(({ lat, lng, id }, index) => ({
      key: id,
      position: { lat, lng },
    }));
    if (markers.length > 0 && map) {
      const bounds = new window.google.maps.LatLngBounds();
      markers.forEach((marker) => bounds.extend(marker.position));
      map.fitBounds(bounds);
      if (markers.length === 1) {
        map.setZoom(ZOOM_LEVEL_FOR_SINGLE_MARKER);
      }
    }
  };

  // from latAndLngs we will get lat and lng of all locations
  const latAndLngs = validPlaces?.map(({ lat, lng }) => ({
    lat,
    lng,
  }));

  // avgLat and avgLng will give us average lat and average lng based on all latAndLngs
  const avgLat =
    latAndLngs?.reduce((total, pos) => total + pos.lat, 0) / latAndLngs?.length;
  const avgLng =
    latAndLngs?.reduce((total, pos) => total + pos.lng, 0) / latAndLngs?.length;

  // https://codesandbox.io/s/react-google-mapsapi-multiple-markers-infowindow-h6vlq?file=/src/Map.js:1242-1260
  const handleActiveMarkerClick = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const loaderTimeout = setTimeout(() => {
    setLoading(false);
  }, 3000);

  if (loading) {
    return <Loader styleOverrides={{ height: "50vh" }} />;
  } else if (!loading && validPlaces.length === 0) {
    return (
      <EmptyState
        styleOverrides={{ height: "50vh" }}
        children={
          <>
            <Typography
              component="div"
              sx={{
                color: "rgba(0, 0, 0, 0.6)",
                fontSize: "0.875rem",
              }}
            >
              {MapHasNoPlacesExplanation}
            </Typography>
          </>
        }
      />
    );
  } else {
    return (
      <Map
        style={{ width: "100%", position: "relative", ...styleOverrides }}
        defaultCenter={{ lat: avgLat, lng: avgLng }}
        defaultZoom={ZOOM_LEVEL}
        gestureHandling={"greedy"}
        disableDefaultUI={true}
        mapId="d264a6e4a3855a72"
        onClick={() => setActiveMarker(null)}
        zoomControl={true}
      >
        {validPlaces.map((validPlace, index) => {
          return (
            <Marker
              place={validPlace}
              key={validPlace.id}
              activeMarker={activeMarker}
              setActiveMarker={setActiveMarker}
              handleActiveMarkerClick={
                onClickOverride ? onClickOverride : handleActiveMarkerClick
              }
              callee={callee}
              index={index}
            />
          );
        })}
      </Map>
    );
  }
};

const Marker = ({
  place,
  activeMarker,
  setActiveMarker,
  handleActiveMarkerClick,
  callee,
  index,
}) => {
  const [markerRef, marker] = useAdvancedMarkerRef();

  const theme = useTheme();
  const { lat, lng } = place;

  const { infoWindowHeader, infoWindowLocation, infoWindowDescription } =
    infoWindowHtmlHelper({
      callee,
      place,
    });
  const { glyphBackground, glyphColor, glyphBorderColor, glyphText } =
    glyphHtmlHelper({
      callee,
      place,
      theme,
      index,
    });

  // TO DO
  // implement showing infowindow on hover once supported, relevant github issues
  // https://github.com/visgl/react-google-maps/discussions/286
  // https://github.com/visgl/react-google-maps/issues/304
  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        position={{
          lat,
          lng,
        }}
        key={place.id}
        clickable={true}
        onClick={() => handleActiveMarkerClick(place.id)}
      >
        <Pin
          background={glyphBackground}
          glyphColor={glyphColor}
          borderColor={glyphBorderColor}
          glyph={glyphText}
        />
      </AdvancedMarker>
      {activeMarker === place.id && (
        <InfoWindow
          anchor={marker}
          onClose={() => setActiveMarker(null)}
          style={{
            textAlign: "left",
          }}
          headerContent={infoWindowHeader}
        >
          {infoWindowLocation}
          {infoWindowDescription}
        </InfoWindow>
      )}
    </>
  );
};
