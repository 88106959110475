import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Alert,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PlaceIcon from "@mui/icons-material/Place";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ShareIcon from "@mui/icons-material/Share";
import { Theme } from "../Theme";
import {
  FavoriteEventExplanation,
  NavigateToShareTripTooltip,
  TripCalendarTooltip,
  TripNotAllEventsMessage,
  TripMapDisabledTooltip,
  TripMapTooltip,
} from "../constants";
import { AddEventButton, FavoritesToggle, ToggleViewButtons } from "./Buttons";
import { validatePlaces } from "../utils/maps_utils";

export const ViewBar = ({
  setCalendarTitle,
  calendarView,
  setCalendarView,
  setEventClicked,
  setDialogOpen,
  currentDay,
  setCurrentDay,
  startDate,
  endDate,
  calendarApi,
  showAddEventButton,
  calendarViewOptions,
  showShareButton,
  showFavorites,
  setShowFavorites,
  events,
  currentUserIsEditor,
  validPlaces,
  tripView,
  setTripView,
  callee,
}) => {
  const { trip_id } = useParams();
  const navigate = useNavigate();
  const currentUserId = localStorage.getItem("user_id");

  const theme = useTheme();
  const smallScreenSize = theme.breakpoints.values.sm;
  const screenIsSmall = useMediaQuery(`(max-width:${smallScreenSize}px)`);
  const [alertDismissed, setAlertDismissed] = useState(false);

  useEffect(() => {
    if (calendarApi) {
      calendarApi.changeView(calendarView, {
        start: startDate,
        end: endDate,
      });
    }
  }, [currentDay, calendarView, startDate, endDate, calendarApi]);

  // listen for changeView since was previously causing race condition
  // when they were all in one useEffect
  useEffect(() => {
    calendarApi?.currentData &&
      setCalendarTitle(calendarApi.currentData.viewTitle);
  }, [calendarApi?.changeView, calendarApi?.currentData, setCalendarTitle]);

  if (!calendarApi) return null;

  let showFavoritesToggle = false;
  if (callee === "sharetrip") {
    if (tripView === "calendar" && events?.favorites?.length > 0) {
      showFavoritesToggle = true;
    } else if (
      tripView === "map" &&
      validatePlaces(events?.favorites).length > 0
    ) {
      showFavoritesToggle = true;
    }
  }

  let disableMapView = false;
  if (showFavorites && validatePlaces(events?.favorites).length === 0)
    disableMapView = true;
  else if (validatePlaces(events?.all).length === 0) disableMapView = true;

  return (
    <Card
      sx={{
        mt: 1,
        backgroundColor: `${Theme.palette.grey["100"]}`,
        borderRadius: "4px 4px 0px 0px",
        padding: 1,
      }}
      elevation={0}
    >
      <Stack spacing={1}>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          sx={{ mt: 1, width: "100%" }}
          alignItems="center"
        >
          {showAddEventButton ? (
            <AddEventButton
              screenIsSmall={screenIsSmall}
              setEventClicked={setEventClicked}
              setDialogOpen={setDialogOpen}
              currentDay={currentDay}
              startDate={startDate}
            />
          ) : (
            ""
          )}
          {tripView === "calendar" ? (
            <Grid item>
              <Tooltip title={"Previous"}>
                <span>
                  <IconButton
                    aria-label="navigate-before"
                    onClick={() => {
                      calendarApi.prev();
                      setCurrentDay(
                        calendarApi.currentData.dateProfile.currentDate
                      );
                    }}
                    disabled={
                      calendarApi.currentData.dateProfile.currentRange.start <=
                      startDate
                        ? true
                        : false
                    }
                    size="small"
                  >
                    <NavigateBeforeIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={"Next"}>
                <span>
                  <IconButton
                    aria-label="navigate-next"
                    onClick={() => {
                      calendarApi.next();
                      setCurrentDay(
                        calendarApi.currentData.dateProfile.currentDate
                      );
                    }}
                    disabled={
                      calendarApi.currentData.dateProfile.currentRange.end >
                      endDate
                        ? true
                        : false
                    }
                    size="small"
                  >
                    <NavigateNextIcon />
                  </IconButton>
                </span>
              </Tooltip>
              {calendarViewOptions ? (
                <FormControl>
                  <InputLabel id="demo-simple-select-label">View</InputLabel>
                  <Select
                    labelId="text-field-calendar-view-label"
                    value={calendarView}
                    label="Type"
                    onChange={(e) => {
                      setCalendarView(e.target.value);
                      localStorage.setItem(
                        `${trip_id}`,
                        JSON.stringify({ calendarView: e.target.value })
                      );
                    }}
                    slotProps={{
                      textField: { label: "Type" },
                    }}
                    id={"text-field-calendar-view"}
                    size="small"
                    defaultValue=""
                  >
                    {calendarViewOptions.map(({ value, label }) => {
                      return (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              ) : (
                ""
              )}
            </Grid>
          ) : (
            <span />
          )}

          <Stack direction="row" alignItems="center" spacing={1}>
            {showFavoritesToggle ? (
              <FavoritesToggle
                showFavorites={showFavorites}
                setShowFavorites={setShowFavorites}
              />
            ) : (
              ""
            )}

            {showShareButton ? (
              <Grid item>
                <Tooltip title={NavigateToShareTripTooltip}>
                  <IconButton
                    onClick={() => navigate(`/trip/share/${trip_id}`)}
                  >
                    <ShareIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            ) : (
              ""
            )}

            <ToggleViewButtons
              items={[
                {
                  key: "calendar",
                  value: "calendar",
                  icon: <CalendarMonthIcon />,
                  tooltip: TripCalendarTooltip,
                },
                {
                  key: "map",
                  value: "map",
                  icon: <PlaceIcon />,
                  tooltip: validPlaces?.length
                    ? TripMapTooltip
                    : TripMapDisabledTooltip,
                  disabled: disableMapView,
                },
              ]}
              view={tripView}
              handleView={(event, newView) => {
                if (newView !== null) {
                  setTripView(newView);
                  if (currentUserId && callee) {
                    localStorage.setItem(`${callee}_${trip_id}_view`, newView);
                  }
                }
              }}
            />
          </Stack>
        </Stack>

        {currentUserIsEditor &&
        callee === "sharetrip" &&
        tripView === "calendar" ? (
          <Typography
            component="span"
            sx={{
              color: "rgba(0, 0, 0, 0.6)",
              fontSize: "0.875rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {FavoriteEventExplanation}
          </Typography>
        ) : (
          ""
        )}

        {!alertDismissed &&
        tripView === "map" &&
        validPlaces?.length !== events?.all?.length ? (
          <Alert
            severity="info"
            sx={{
              width: "100%",
            }}
            onClose={() => setAlertDismissed(true)}
          >
            {TripNotAllEventsMessage}
          </Alert>
        ) : (
          ""
        )}
      </Stack>
    </Card>
  );
};
