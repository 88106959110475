import { useState, useEffect, useCallback } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Step,
  Stepper,
  StepLabel,
  Tooltip,
  useTheme,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { TripFieldsCopy1, TripFieldsCopy2 } from "../";
import { CopyTripDialogTitle } from "./";
import { addDays, utcDate, diffDays } from "../../utils/date_utils";
import {
  CopyTripButtonTooltip,
  CopyTripStep1Explantion,
  CopyTripStep2Explantion,
} from "../../constants";
import { handleNext, handleBack } from "../../utils/dialog_utils";

export const CopyTripDialog = ({
  open,
  handleClose,
  copyTripHandler,
  trip,
  events,
  loading,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const originalTripLengthInDays = diffDays(trip.start_date, trip.end_date);
  const [newTripLengthInDays, setNewTripLengthInDays] = useState(null);

  const [title, setTitle] = useState(trip ? "Copy of " + trip.title : "");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [rowSelectionModel, setRowSelectionModel] = useState(
    events.all.map((e) => e.id)
  );

  useEffect(() => {
    if (startDate && endDate === null) {
      // assume initial trip length equal to original trip length,
      // user can change later
      setEndDate(utcDate(addDays(startDate, originalTripLengthInDays)));
    }
    setNewTripLengthInDays(diffDays(startDate, endDate));
  }, [startDate, endDate, originalTripLengthInDays]);

  const reinitializeState = useCallback(() => {
    setTitle("");
    setStartDate(null);
    setEndDate(null);
  }, []);

  // once closed, reinitialize state
  useEffect(() => {
    if (!open) {
      reinitializeState();
    }
  }, [open, reinitializeState]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!loading) {
          handleClose();
          setActiveStep(0);
        }
      }}
      fullScreen={fullScreen}
      PaperProps={{
        component: "form",
      }}
      sx={{
        "& .MuiDialog-container": {
          alignItems: "flex-start",
        },
      }}
    >
      <CopyTripDialogTitle handleClose={handleClose} loading={loading} />

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stepper activeStep={activeStep}>
              <Step>
                <StepLabel
                  onClick={() => {
                    setActiveStep(0);
                  }}
                >
                  {CopyTripStep1Explantion}
                </StepLabel>
              </Step>
              <Step>
                <StepLabel
                  onClick={() => {
                    title && startDate && setActiveStep(1);
                  }}
                >
                  {CopyTripStep2Explantion}
                </StepLabel>
              </Step>
            </Stepper>
          </Grid>

          {activeStep === 0 ? (
            <Grid item xs={12}>
              <TripFieldsCopy1
                title={title}
                setTitle={setTitle}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
              />
            </Grid>
          ) : (
            ""
          )}
          {activeStep === 1 ? (
            <Grid item xs={12}>
              <TripFieldsCopy2
                events={events}
                rowSelectionModel={rowSelectionModel}
                setRowSelectionModel={setRowSelectionModel}
              />
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        {activeStep === 0 ? (
          <Button
            onClick={() => handleNext(activeStep, setActiveStep)}
            variant="contained"
            mt={1}
            disabled={
              title &&
              startDate &&
              endDate &&
              newTripLengthInDays === originalTripLengthInDays
                ? false
                : true
            }
          >
            Next
          </Button>
        ) : (
          ""
        )}
        {activeStep === 1 ? (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Button
              onClick={() => handleBack(activeStep, setActiveStep)}
              disabled={loading}
            >
              Back
            </Button>
            <Tooltip
              title={rowSelectionModel.length ? "" : CopyTripButtonTooltip}
            >
              <span>
                <Button
                  variant="contained"
                  disabled={loading || !rowSelectionModel.length}
                  onClick={() => {
                    copyTripHandler({
                      updatedTripInfo: {
                        title,
                        startDate: startDate.$d,
                        endDate: endDate.$d,
                      },
                      updatedEvents: events.all.filter((event) =>
                        rowSelectionModel.includes(event.id)
                      ),
                    });
                  }}
                >
                  Copy
                </Button>
              </span>
            </Tooltip>
          </Stack>
        ) : (
          ""
        )}
      </DialogActions>
    </Dialog>
  );
};
